import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Careers from './components/Careers';
import Contact from './components/Contact';
import Gallery from './components/Gallery';
import Pricing from './components/Pricing';
import Service from './components/Service';
import Team from './components/Team';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';

function App() {
 
  return (
    <Router>
      
       <Routes>
       <Route exact path="/" element={<Home/>}/>
       <Route exact path="/About" element={<About/>}/>
       <Route exact path="/Careers" element={<Careers/>}/>
       <Route exact path="/Contact" element={<Contact/>}/>
       <Route exact path="/Gallery" element={<Gallery/>}/>
       <Route exact path="/Pricing" element={<Pricing/>}/>
       <Route exact path="/Service" element={<Service/>}/>
       <Route exact path="/Team" element={<Team/>}/>
       <Route exact path="/Navbar" element={<Navbar/>}/>
          </Routes>
      
    </Router>
    
  );
}

export default App;
