import React from 'react'
import { NavLink } from 'react-router-dom';
import Footer from './Footer';
export default function Contact() {
    const openmenu = () => {
        var element = document.getElementById("phonemenu");
        element.classList.add("active");
    }
    const closemenu = () =>{
        var element = document.getElementById("phonemenu");
        element.classList.remove("active");
    }

  return (
    <div>
 <main className="page-wrapper">
       
        <div className="header-top-news bg-image1">
            
            <div className="icon-close">
               
            </div>
        </div>
        <header className="rn-header header-default header-left-align header-not-transparent header-sticky">
            <div className="container position-relative">
                <div className="row align-items-center">
                    <div className="col-lg-9 col-md-6 col-4 position-static">
                        <div className="header-left d-flex">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-light" src="assets/images/logo/logo.png" alt="Corporate Logo"/>
                                    <img className="logo-dark" src="assets/images/logo/logo.png" alt="Corporate Logo"/>
                                </a>
                            </div>
                            <nav className="mainmenu-nav d-none d-lg-block">
                                              <ul className="mainmenu">
                                                  <li className="has-droupdown has-menu-child-item"><NavLink to="/">Home</NavLink>
                                                  </li>
                                                  <li><NavLink to="/About">About</NavLink></li>
                                                  <li><NavLink to="/Service">Service</NavLink></li>
                                                  <li><NavLink to="/Team">Team</NavLink></li>
                                                  <li><NavLink to="/Gallery">Gallery</NavLink></li>
                                                  <li><NavLink to="/Pricing">Pricing</NavLink></li>
                                                  <li><NavLink to="/Careers">Careers</NavLink></li>
                                                  <li><NavLink to="/Contact">Contact</NavLink></li>
                                              </ul>
                                          </nav>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-8">
                        <div className="header-right">
                            <div className="header-btn">
                              
                                <ul className="social-icon social-default justify-content-start ">
                                        <li><a target="_blank" href="https://m.facebook.com/Techpoints/">
                                                <i className="feather-facebook"></i>
                                            </a>
                                        </li>
                                        <li className="d-none d-lg-block"><a target="_blank" href="https://twitter.com/Techpoints">
                                                <i className="feather-twitter"></i>
                                            </a>
                                        </li>
                                        <li className="d-none d-lg-block"><a target="_blank" href="https://www.instagram.com/Techpoints2018/?igshid=YmMyMTA2M2Y=">
                                                <i className="feather-instagram"></i>
                                            </a>
                                        </li>
                                        <li><a target="_blank" href="https://www.linkedin.com/in/Techpoints-pvt-ltd-353aab23a/">
                                                <i className="feather-linkedin"></i>
                                            </a>
                                        </li>
                                    </ul>
                            </div>

                          
                            <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                                <div className="hamberger">
                                    <button onClick={openmenu} className="hamberger-button">
                                        <i className="feather-menu"></i>
                                    </button>
                                </div>
                            </div>
                           

                           

                        </div>
                    </div>
                </div>
            </div>
        </header>
       
        <div className='popup-mobile-menu ' id='phonemenu'>
            <div className="inner">
                <div className="header-top">
                    <div className="logo">
                        <a href="/">
                            <img className="logo-light" src="assets/images/logo/logo.png" alt="Corporate Logo"/>
                            <img className="logo-dark" src="assets/images/logo/logo.png" alt="Corporate Logo"/>
                        </a>
                    </div>
                    <div className="close-menu">
                        <button onClick={closemenu} className="close-button">
                            <i className="feather-x"></i>
                        </button>
                    </div>
                </div>
                <ul className="mainmenu">
                    
                        <li><NavLink to="/">Home</NavLink></li>
                        <li><NavLink to="/About">About</NavLink></li>
                        <li><NavLink to="/Service">Service</NavLink></li>
                        <li><NavLink to="/Team">Team</NavLink></li>
                        <li><NavLink to="/Gallery">Gallery</NavLink></li>
                        <li><NavLink to="/Pricing">Pricing</NavLink></li>
                        <li><NavLink to="/Careers">Careers</NavLink></li>
                        <li><NavLink to="/Contact">Contact</NavLink></li>
                </ul>
            </div>
        </div>

       
        <div>
            <div className="rn-gradient-circle"></div>
            <div className="rn-gradient-circle theme-pink"></div>
        </div>
        



       
        <div className="main-content">

            <div className="rwt-contact-area rn-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mb--40">
                            <div className="section-title text-center" >
                                <h4 className="subtitle "><span className="theme-gradient">Contact Form</span></h4>
                                <h2 className="title w-600 mb--20">Our Contact Address Here.</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row row--15">
                        <div className="col-lg-12">
                            <div className="rn-contact-address mt_dec--30">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-12">
                                        <div className="rn-address">
                                            <div className="icon">
                                                <i className="feather-headphones"></i>
                                            </div>
                                            <div className="inner">
                                                <h4 className="title">Contact Phone Number</h4>
                                                <p><a href="#"> +91 6203381655</a></p>
                                                <p><a href="#">+91 7764080420</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-12">
                                        <div className="rn-address">
                                            <div className="icon">
                                                <i className="feather-mail"></i>
                                            </div>
                                            <div className="inner">
                                                <h4 className="title">Our Email Address</h4>
                                                <p><a href="mailto:techpointshelp@gmail.com">TechpointsHelp@gmail.com</a></p>
                                                <p><br/></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-12">
                                        <div className="rn-address">
                                            <div className="icon">
                                                <i className="feather-map-pin"></i>
                                            </div>
                                            <div className="inner">
                                                <h4 className="title">Our Location</h4>
                                                <p>HO. Chakia, Station Road, Near Old Post Office Chakia, Bihar </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt--40 row--15">
                        <div className="col-lg-7">
                            <form className="contact-form-1 rwt-dynamic-form" id="contact-form" method="POST" action="">
                                <div className="form-group">
                                    <input type="text" name="contact-name" id="contact-name" placeholder="Your Name"/>
                                </div>
                                <div className="form-group">
                                    <input type="text" name="contact-phone" id="contact-phone" placeholder="Phone Number"/>
                                </div>
                                <div className="form-group">
                                    <input type="email" id="contact-email" name="contact-email" placeholder="Your Email"/>
                                </div>

                                <div className="form-group">
                                    <input type="text" id="subject" name="subject" placeholder="Your Subject"/>
                                </div>

                                <div className="form-group">
                                    <textarea name="contact-message" id="contact-message" placeholder="Your Message"></textarea>
                                </div>

                                <div className="form-group">
                                    <button name="submit" type="button" id="submit" className="btn-default btn-large rn-btn">
                                        <span>Submit Now</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-5 mt_md--30 mt_sm--30">
                            <div className="google-map-style-1">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3573.1778930069495!2d85.04441085049442!3d26.417735283261532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ecd6c867324697%3A0xd65f221048117e0c!2sChakia!5e0!3m2!1sen!2sin!4v1658819763690!5m2!1sen!2sin" width="600" height="550" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
       
        
        <Footer></Footer>
        <div className="copyright-area copyright-style-one">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-8 col-sm-12 col-12">
                        <div className="copyright-left">
                            <ul className="ft-menu link-hover">
                                
                                <li>
                                    <a href="/Contact">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-4 col-sm-12 col-12">
                        <div className="copyright-right text-center text-lg-end">
                            <p className="copyright-text">© Techpoints 2018</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      
    </main>

    
    <div className="rn-back-top">
        <i className="feather-arrow-up"></i>
    </div>
    </div>
  )
}
