import React from 'react'
import { NavLink } from 'react-router-dom';
import Footer from './Footer';
export default function Pricing() {
    const openmenu = () => {
        var element = document.getElementById("phonemenu");
        element.classList.add("active");
    }
    const closemenu = () =>{
        var element = document.getElementById("phonemenu");
        element.classList.remove("active");
    }

  return (
    <div>
 <main className="page-wrapper">
       
        <div className="header-top-news bg-image1">
            
            <div className="icon-close">
               
            </div>
        </div>
        <header className="rn-header header-default header-left-align header-not-transparent header-sticky">
            <div className="container position-relative">
                <div className="row align-items-center">
                    <div className="col-lg-9 col-md-6 col-4 position-static">
                        <div className="header-left d-flex">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-light" src="assets/images/logo/logo.png" alt="Corporate Logo"/>
                                    <img className="logo-dark" src="assets/images/logo/logo.png" alt="Corporate Logo"/>
                                </a>
                            </div>
                            <nav className="mainmenu-nav d-none d-lg-block">
                                              <ul className="mainmenu">
                                                  <li className="has-droupdown has-menu-child-item"><NavLink to="/">Home</NavLink>
                                                  </li>
                                                  <li><NavLink to="/About">About</NavLink></li>
                                                  <li><NavLink to="/Service">Service</NavLink></li>
                                                  <li><NavLink to="/Team">Team</NavLink></li>
                                                  <li><NavLink to="/Gallery">Gallery</NavLink></li>
                                                  <li><NavLink to="/Pricing">Pricing</NavLink></li>
                                                  <li><NavLink to="/Careers">Careers</NavLink></li>
                                                  <li><NavLink to="/Contact">Contact</NavLink></li>
                                              </ul>
                                          </nav>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-8">
                        <div className="header-right">
                            <div className="header-btn">
                              
                                <ul className="social-icon social-default justify-content-start ">
                                        <li><a target="_blank" href="https://m.facebook.com/Techpoints/">
                                                <i className="feather-facebook"></i>
                                            </a>
                                        </li>
                                        <li className="d-none d-lg-block"><a target="_blank" href="https://twitter.com/Techpoints">
                                                <i className="feather-twitter"></i>
                                            </a>
                                        </li>
                                        <li className="d-none d-lg-block"><a target="_blank" href="https://www.instagram.com/Techpoints2018/?igshid=YmMyMTA2M2Y=">
                                                <i className="feather-instagram"></i>
                                            </a>
                                        </li>
                                        <li><a target="_blank" href="https://www.linkedin.com/in/Techpoints-pvt-ltd-353aab23a/">
                                                <i className="feather-linkedin"></i>
                                            </a>
                                        </li>
                                    </ul>
                            </div>

                          
                            <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                                <div className="hamberger">
                                    <button onClick={openmenu} className="hamberger-button">
                                        <i className="feather-menu"></i>
                                    </button>
                                </div>
                            </div>
                           

                           
                         

                        </div>
                    </div>
                </div>
            </div>
        </header>
       
        <div className='popup-mobile-menu ' id='phonemenu'>
            <div className="inner">
                <div className="header-top">
                    <div className="logo">
                        <a href="/">
                            <img className="logo-light" src="assets/images/logo/logo.png" alt="Corporate Logo"/>
                            <img className="logo-dark" src="assets/images/logo/logo.png" alt="Corporate Logo"/>
                        </a>
                    </div>
                    <div className="close-menu">
                        <button onClick={closemenu} className="close-button">
                            <i className="feather-x"></i>
                        </button>
                    </div>
                </div>
                <ul className="mainmenu">
                    
                        <li><NavLink to="/">Home</NavLink></li>
                        <li><NavLink to="/About">About</NavLink></li>
                        <li><NavLink to="/Service">Service</NavLink></li>
                        <li><NavLink to="/Team">Team</NavLink></li>
                        <li><NavLink to="/Gallery">Gallery</NavLink></li>
                        <li><NavLink to="/Pricing">Pricing</NavLink></li>
                        <li><NavLink to="/Careers">Careers</NavLink></li>
                        <li><NavLink to="/Contact">Contact</NavLink></li>
                </ul>
            </div>
        </div>

        <div>
            <div className="rn-gradient-circle"></div>
            <div className="rn-gradient-circle theme-pink"></div>
        </div>
        


      
        <div className="main-content">
         
            <div className="rwt-pricing-area rn-section-gap">
                <div className="container">
                    <div className="row mb--40 mb_sm--0">
                        <div className="col-lg-12">
                            <div className="section-title text-center">
                                <h4 className="subtitle "><span className="theme-gradient">Pricing</span></h4>
                                <h2 className="title w-600 mb--20">Our Pricing  .</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row row--30">
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="rn-pricing">
                                <div className="pricing-table-inner">
                                    <div className="pricing-header">
                                        <h4 className="title">Free</h4>
                                        <div className="pricing">
                                            <div className="price-wrapper"><span className="currency">$</span><span
                                                    className="price">29</span></div><span className="subtitle">USD Per
                                                Month</span>
                                        </div>
                                    </div>
                                    <div className="pricing-body">
                                        <ul className="list-style--1">
                                            <li><i className="feather-check"></i> 5 PPC Campaigns</li>
                                            <li><i className="feather-check"></i> Digital Marketing</li>
                                            <li><i className="feather-check"></i> Marketing Agency</li>
                                            <li><i className="feather-check"></i> Seo Friendly</li>
                                            <li><i className="feather-check"></i> UI/UX designs</li>
                                        </ul>
                                    </div>
                                    <div className="pricing-footer"><a className="btn-default btn-border" href="#">Purchase Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="rn-pricing active">
                                <div className="pricing-table-inner">
                                    <div className="pricing-header">
                                        <h4 className="title">Business</h4>
                                        <div className="pricing">
                                            <div className="price-wrapper"><span className="currency">$</span><span
                                                    className="price">50</span></div><span className="subtitle">USD Per
                                                Month</span>
                                        </div>
                                    </div>
                                    <div className="pricing-body">
                                        <ul className="list-style--1">
                                            <li><i className="feather-check"></i> 5 PPC Campaigns</li>
                                            <li><i className="feather-check"></i> Keep 100% Royalties</li>
                                            <li><i className="feather-check"></i> App Development</li>
                                            <li><i className="feather-check"></i> Seo Friendly</li>
                                            <li><i className="feather-check"></i> UI/UX designs</li>
                                        </ul>
                                    </div>
                                    <div className="pricing-footer"><a className="btn-default" href="#">Purchase Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="rn-pricing">
                                <div className="pricing-table-inner">
                                    <div className="pricing-header">
                                        <h4 className="title">Advanced</h4>
                                        <div className="pricing">
                                            <div className="price-wrapper"><span className="currency">$</span><span
                                                    className="price">100</span></div><span className="subtitle">USD Per
                                                Month</span>
                                        </div>
                                    </div>
                                    <div className="pricing-body">
                                        <ul className="list-style--1">
                                            <li><i className="feather-check"></i> 50 PPC Campaigns</li>
                                            <li><i className="feather-check"></i> SEO Marketing</li>
                                            <li><i className="feather-check"></i> Marketing Agency</li>
                                            <li><i className="feather-check"></i> Seo Friendly</li>
                                            <li><i className="feather-check"></i> Application Manage</li>
                                        </ul>
                                    </div>
                                    <div className="pricing-footer"><a className="btn-default btn-border" href="#">Purchase Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           

        </div>
       
        
       <Footer></Footer>
        <div className="copyright-area copyright-style-one">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-8 col-sm-12 col-12">
                        <div className="copyright-left">
                            <ul className="ft-menu link-hover">
                               
                                <li>
                                    <a href="/Contact">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-4 col-sm-12 col-12">
                        <div className="copyright-right text-center text-lg-end">
                            <p className="copyright-text">© Techpoints 2018</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      
    </main>

   
    <div className="rn-back-top">
        <i className="feather-arrow-up"></i>
    </div>

    </div>
  )
}
