import React from 'react';
import  {useState} from 'react';
import { NavLink } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Footer from './Footer';



export default function Careers() {
   
    const openmenu = () => {
        var element = document.getElementById("phonemenu");
        element.classList.add("active");
    }
    const closemenu = () =>{
        var element = document.getElementById("phonemenu");
        element.classList.remove("active");
    }

  return (
    <div>
 <main className="page-wrapper">
       
        <div className="header-top-news bg-image1">
            
            <div className="icon-close">
               
            </div>
        </div>
        <header className="rn-header header-default header-left-align header-not-transparent header-sticky">
            <div className="container position-relative">
                <div className="row align-items-center">
                    <div className="col-lg-9 col-md-6 col-4 position-static">
                        <div className="header-left d-flex">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-light" src="assets/images/logo/logo.png" alt="Corporate Logo"/>
                                    <img className="logo-dark" src="assets/images/logo/logo.png" alt="Corporate Logo"/>
                                </a>
                            </div>
                            <nav className="mainmenu-nav d-none d-lg-block">
                                              <ul className="mainmenu">
                                                  <li className="has-droupdown has-menu-child-item"><NavLink to="/">Home</NavLink>
                                                  </li>
                                                  <li><NavLink to="/About">About</NavLink></li>
                                                  <li><NavLink to="/Service">Service</NavLink></li>
                                                  <li><NavLink to="/Team">Team</NavLink></li>
                                                  <li><NavLink to="/Gallery">Gallery</NavLink></li>
                                                  <li><NavLink to="/Pricing">Pricing</NavLink></li>
                                                  <li><NavLink to="/Careers">Careers</NavLink></li>
                                                  <li><NavLink to="/Contact">Contact</NavLink></li>
                                              </ul>
                                          </nav>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-8">
                        <div className="header-right">
                            <div className="header-btn">
                              
                                <ul className="social-icon social-default justify-content-start ">
                                        <li><a target="_blank" href="https://m.facebook.com/Techpoints/">
                                                <i className="feather-facebook"></i>
                                            </a>
                                        </li>
                                        <li className="d-none d-lg-block"><a target="_blank" href="https://twitter.com/Techpoints">
                                                <i className="feather-twitter"></i>
                                            </a>
                                        </li>
                                        <li className="d-none d-lg-block"><a target="_blank" href="https://www.instagram.com/Techpoints2018/?igshid=YmMyMTA2M2Y=">
                                                <i className="feather-instagram"></i>
                                            </a>
                                        </li>
                                        <li><a target="_blank" href="https://www.linkedin.com/in/Techpoints-pvt-ltd-353aab23a/">
                                                <i className="feather-linkedin"></i>
                                            </a>
                                        </li>
                                    </ul>
                            </div>

                          
                            <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                                <div className="hamberger">
                                    <button onClick={openmenu} className="hamberger-button">
                                        <i className="feather-menu"></i>
                                    </button>
                                </div>
                            </div>
                           

                          
                          

                        </div>
                    </div>
                </div>
            </div>
        </header>
       
        <div className='popup-mobile-menu ' id='phonemenu'>
            <div className="inner">
                <div className="header-top">
                    <div className="logo">
                        <a href="/">
                            <img className="logo-light" src="assets/images/logo/logo.png" alt="Corporate Logo"/>
                            <img className="logo-dark" src="assets/images/logo/logo.png" alt="Corporate Logo"/>
                        </a>
                    </div>
                    <div className="close-menu">
                        <button onClick={closemenu} className="close-button">
                            <i className="feather-x"></i>
                        </button>
                    </div>
                </div>
                <ul className="mainmenu">
                    
                        <li><NavLink to="/">Home</NavLink></li>
                        <li><NavLink to="/About">About</NavLink></li>
                        <li><NavLink to="/Service">Service</NavLink></li>
                        <li><NavLink to="/Team">Team</NavLink></li>
                        <li><NavLink to="/Gallery">Gallery</NavLink></li>
                        <li><NavLink to="/Pricing">Pricing</NavLink></li>
                        <li><NavLink to="/Careers">Careers</NavLink></li>
                        <li><NavLink to="/Contact">Contact</NavLink></li>
                </ul>
            </div>
        </div>

        <div>
            <div className="rn-gradient-circle"></div>
            <div className="rn-gradient-circle theme-pink"></div>
        </div>
      

        <div className="main-content">

            <div className="rwt-contact-area rn-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mb--40">
                            <div className="section-title text-center" >
                                <h2 className="subtitle "><span className="theme-gradient">Jobs And Careers</span></h2>
                                <h4 className="title w-600 mb--20">Team up with the best minds in the industry and build a great career.</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row row--15">
                        <div className="col-lg-12">
                            <div className="rn-contact-address mt_dec--30">
                                <div className="row">
                                <div className="col-lg-12 col-md-12 col-12">
                                    <div id="accordion">
                                          <div className="">
                                            <div className="card-header" id="headingOne">
                                              <h5 className="mb-0 border">
                                                <div className="btn text-white " style={{fontSize: 25}} data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                  Creative Designer
                                                </div>
                                              </h5>
                                            </div>
                                        
                                            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                              <div className="card-body">
                                              We are looking for a graphics designer to work with client and prepare images to be used in the different field. Your job will include but not be limited to the following:Format images (resolution, sizing, font, image type) based on client requirementsWork with clients and in-house team to create a visually appealing summary of clients research (e.g., graphical abstracts, table of contents graphics)Develop concepts, graphics and layouts for company logos, report templates, leaflets, brochures and other materialReview final layouts and suggest improvements if required
                                              </div>
                                            </div>
                                          </div>
                                          <div className="">
                                            <div className="card-header" id="headingTwo">
                                              <h5 className="mb-0 border">
                                                <div className="btn text-white collapsed" style={{fontSize: 25}} data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                MOBILE APP DEVELOPER
                                                </div>
                                              </h5>
                                            </div>
                                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                              <div className="card-body">
                                              Build your career as a Mobile App Developer at Techpoints – a leading windows mobile app development company.
                                              </div>
                                            </div>
                                          </div>
                                          <div className="">
                                            <div className="card-header" id="headingThree">
                                              <h5 className="mb-0 border">
                                                <div className="btn text-white collapsed" style={{fontSize: 25}} data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                 PHP DEVELOPER
                                                </div>
                                              </h5>
                                            </div>
                                            <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                              <div className="card-body">
                                                 Build your career as a Php Developer at Techpoints – a leading Website app development company.
                                              </div>
                                            </div>
                                          </div>
                                          <div className="">
                                            <div className="card-header" id="headingThree">
                                              <h5 className="mb-0 border">
                                                <div className="btn text-white collapsed" style={{fontSize: 25}} data-toggle="collapse" data-target="#collapse4" aria-expanded="false" aria-controls="collapseThree">
                                                FLUTTER DEVELOPER
                                                </div>
                                              </h5>
                                            </div>
                                            <div id="collapse4" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                              <div className="card-body">
                                                Knowledge of web hosting industry. Experience in sales of web hosting services. Strong sales and presentation skills.
                                              </div>
                                            </div>
                                          </div>
                                           <div className="">
                                            <div className="card-header" id="headingThree">
                                              <h5 className="mb-0 border">
                                                <div className="btn text-white collapsed " style={{fontSize: 25}} data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapseThree">
                                                MARKETING EXECUTIVE
                                                </div>
                                              </h5>
                                            </div>
                                            <div id="collapse5" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                              <div className="card-body">
                                               Very urgently required Marketing executives for a leading software company.Exp-1-5yrs candidate must be smart, dynamic, educated, good communication skill in English, target oriented
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>

                  
                </div>
            </div>

        </div>
       
        
       <Footer></Footer>
        <div className="copyright-area copyright-style-one">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-8 col-sm-12 col-12">
                        <div className="copyright-left">
                            <ul className="ft-menu link-hover">
                               
                                <li>
                                    <a href="/Contact">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-4 col-sm-12 col-12">
                        <div className="copyright-right text-center text-lg-end">
                            <p className="copyright-text">© Techpoints 2018</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    </main>

    
    <div className="rn-back-top">
        <i className="feather-arrow-up"></i>
    </div>

    </div>
  )
}
