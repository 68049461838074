import React from 'react'
import { NavLink } from 'react-router-dom';
import Footer from './Footer';

export default function Home() {
    const openmenu = () => {
        var element = document.getElementById("phonemenu");
        element.classList.add("active");
    }
    const closemenu = () =>{
        var element = document.getElementById("phonemenu");
        element.classList.remove("active");
    }

  return (
    <div>
 <main className="page-wrapper">
       
        <div className="header-top-news bg-image1">
            
            <div className="icon-close">
               
            </div>
        </div>
        <header className="rn-header header-default header-left-align header-not-transparent header-sticky">
            <div className="container position-relative">
                <div className="row align-items-center">
                    <div className="col-lg-9 col-md-6 col-4 position-static">
                        <div className="header-left d-flex">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-light" src="assets/images/logo/logo.png" alt="Corporate Logo"/>
                                    <img className="logo-dark" src="assets/images/logo/logo.png" alt="Corporate Logo"/>
                                </a>
                            </div>
                            <nav className="mainmenu-nav d-none d-lg-block">
                                              <ul className="mainmenu">
                                                  <li className="has-droupdown has-menu-child-item"><NavLink to="/">Home</NavLink>
                                                  </li>
                                                  <li><NavLink to="/About">About</NavLink></li>
                                                  <li><NavLink to="/Service">Service</NavLink></li>
                                                  <li><NavLink to="/Team">Team</NavLink></li>
                                                  <li><NavLink to="/Gallery">Gallery</NavLink></li>
                                                  <li><NavLink to="/Pricing">Pricing</NavLink></li>
                                                  <li><NavLink to="/Careers">Careers</NavLink></li>
                                                  <li><NavLink to="/Contact">Contact</NavLink></li>
                                              </ul>
                                          </nav>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-8">
                        <div className="header-right">
                            <div className="header-btn">
                              
                                <ul className="social-icon social-default justify-content-start ">
                                        <li><a target="_blank" href="https://m.facebook.com/Techpoints/">
                                                <i className="feather-facebook"></i>
                                            </a>
                                        </li>
                                        <li className="d-none d-lg-block"><a target="_blank" href="https://twitter.com/Techpoints">
                                                <i className="feather-twitter"></i>
                                            </a>
                                        </li>
                                        <li className="d-none d-lg-block"><a target="_blank" href="https://www.instagram.com/Techpoints2018/?igshid=YmMyMTA2M2Y=">
                                                <i className="feather-instagram"></i>
                                            </a>
                                        </li>
                                        <li><a target="_blank" href="https://www.linkedin.com/in/Techpoints-pvt-ltd-353aab23a/">
                                                <i className="feather-linkedin"></i>
                                            </a>
                                        </li>
                                    </ul>
                            </div>

                          
                            <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                                <div className="hamberger">
                                    <button onClick={openmenu} className="hamberger-button">
                                        <i className="feather-menu"></i>
                                    </button>
                                </div>
                            </div>
                           

                          

                        </div>
                    </div>
                </div>
            </div>
        </header>
       
        <div className='popup-mobile-menu ' id='phonemenu'>
            <div className="inner">
                <div className="header-top">
                    <div className="logo">
                        <a href="/">
                            <img className="logo-light" src="assets/images/logo/logo.png" alt="Corporate Logo"/>
                            <img className="logo-dark" src="assets/images/logo/logo.png" alt="Corporate Logo"/>
                        </a>
                    </div>
                    <div className="close-menu">
                        <button onClick={closemenu} className="close-button">
                            <i className="feather-x"></i>
                        </button>
                    </div>
                </div>
                <ul className="mainmenu">
                    
                        <li><NavLink to="/">Home</NavLink></li>
                        <li><NavLink to="/About">About</NavLink></li>
                        <li><NavLink to="/Service">Service</NavLink></li>
                        <li><NavLink to="/Team">Team</NavLink></li>
                        <li><NavLink to="/Gallery">Gallery</NavLink></li>
                        <li><NavLink to="/Pricing">Pricing</NavLink></li>
                        <li><NavLink to="/Careers">Careers</NavLink></li>
                        <li><NavLink to="/Contact">Contact</NavLink></li>
                </ul>
            </div>
        </div>

                  <div>
                      <div className="rn-gradient-circle"></div>
                      <div className="rn-gradient-circle theme-pink"></div>
                  </div>




                 
                  <div className="slider-area slider-bg-image slider-style-5 bg-overlay-solid height-850 bg_image bg_image--13" >
                      <div className="container">
                          <div className="row">
                              <div className="col-lg-12">
                                  <div className="inner text-start">
                                      <h4 className="subtitle">MEET CONSULTING</h4>
                                      <h1 className="title display-one">Largest Advertising <br /> Agency in India </h1>
                                      <div className="button-group mt--40 mt_sm--20"><a className="btn-default" target="_blank" href="/Contact">Contact Us</a></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>




                  <div className="rbt-separator-mid">
                      <div className="container">
                          <hr className="rbt-separator m-0"/>
                          </div>
                  </div>





                  <div className="rn-service-area rn-section-gap">
                      <div className="container">
                          <div className="row">
                              <div className="col-lg-12">
                                  <div className="section-title text-center" >
                                      <h4 className="subtitle ">
                                          <span className="theme-gradient">What we can do for you</span>
                                      </h4>
                                      <h2 className="title w-600 mb--20">Services provide for you.</h2>
                                      <p className="description b1">There are many variations of passages of Lorem Ipsum
                                          available, <br/>but the majority have suffered alteration.</p>
                                  </div>
                              </div>
                          </div>

                          <div className="row row--15 service-wrapper">
                              <div className="col-lg-4 col-md-6 col-sm-6 col-12" >
                                  <div className="service service__style--1 bg-color-blackest radius mt--20 rbt-border text-start">
                                      <div className="icon">
                                          <i className="feather-activity"></i>
                                      </div>
                                      <div className="content">
                                          <h4 className="title w-600">
                                              <a href="#">Web Analytics</a>
                                          </h4>
                                          <p className="description b1 color-gray mb--0">Techpoints specializing in customize software development, Web Analytics services .</p>
                                      </div>
                                  </div>
                              </div>

                              <div className="col-lg-4 col-md-6 col-sm-6 col-12" >
                                  <div className="service service__style--1 bg-color-blackest radius mt--20 rbt-border text-start">
                                      <div className="icon">
                                          <i className="feather-cast"></i>
                                      </div>
                                      <div className="content">
                                          <h4 className="title w-600">
                                              <a href="#">Web Design and Development </a>
                                          </h4>
                                          <p className="description b1 color-gray mb--0">Techpoints is a house of Innovative and Creative custom Web design and Web Development Company.</p>
                                      </div>
                                  </div>
                              </div>

                              <div className="col-lg-4 col-md-6 col-sm-6 col-12" >
                                  <div className="service service__style--1 bg-color-blackest radius mt--20 rbt-border text-start">
                                      <div className="icon">
                                          <i className="feather-map"></i>
                                      </div>
                                      <div className="content">
                                          <h4 className="title w-600">
                                              <a href="#">Android  App Development</a>
                                          </h4>
                                          <p className="description b1 color-gray mb--0">Techpoints is a house of Innovative and Creative custom Android design and Development Company.</p>
                                      </div>
                                  </div>
                              </div>

                          </div>
                      </div>
                  </div>

                  <div className="rbt-separator-mid">
                      <div className="container">
                          <hr className="rbt-separator m-0"/>
                         </div>
                  </div>

                  <div className="rwt-about-area rn-section-gap">
                      <div className="container">
                          <div className="row row--30 align-items-center">
                              <div className="col-lg-5">
                                  <div className="thumbnail" >
                                      <img className="w-100" src="assets/images/about/about-1.png" alt="About Images"/>
                                     </div>
                              </div>
                              <div className="col-lg-7 mt_md--40 mt_sm--40">
                                  <div className="content"  >
                                      <div className="section-title">
                                          <h4 className="subtitle"><span className="theme-gradient">Corporate About.</span></h4>
                                          <h2 className="title mt--10">About Our Business.</h2>

                                          <p style = {{ textAlign: "justify"}}>At Techpoints , we understand how important the first look of a Company Website is! Hence, we work constantly to deliver the best look as per the requirement of our client. First impressions are very important and we aim to get that deal sealed for you right away. Hence, our team of designers and developers not only create, but also work as consultants and advisors to bring out the best that your firm has to offer.</p>

                                          <ul className="list-icon">
                                              <li><span className="icon"><i className="feather-check"></i></span> Website Designing.</li>
                                              <li><span className="icon">
                                                  <i className="feather-check"></i>
                                              </span> Web Application Development.</li>
                                              <li><span className="icon">
                                                  <i className="feather-check"></i>
                                              </span> Payment Gateway integration.</li>
                                              <li><span className="icon">
                                                  <i className="feather-check"></i>
                                              </span> Api integration</li>
                                              <li><span className="icon">
                                                  <i className="feather-check"></i>
                                              </span> Graphic Design.</li>
                                              <li><span className="icon">
                                                  <i className="feather-check"></i>
                                              </span> Digital Marketing</li>
                                              <li>
                                              <span className="icon">
                                              <i className="feather-check"></i>
                                          </span>Documentary Shoot Video Promotion</li>
                                  </ul>

                                  <div className="read-more-btn mt--40">
                                      <a className="btn-default btn-icon" href="/About">More About Us <i
                                          className="icon feather-arrow-right"></i></a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
        </div><div className="rbt-separator-mid">
              <div className="container">
                  <hr className="rbt-separator m-0"/>
                 </div>
          </div><div className="rwt-split-area">
              <div className="wrapper">
                  <div className="rn-splite-style bg-color-blackest">
                      <div className="split-wrapper">
                          <div className="row g-0 radius-10 align-items-center">
                              <div className="col-lg-12 col-xl-6 col-12">
                                  <div className="thumbnail"><img src="assets/images/split/split-03.jpg" alt="split Images"/>
                                  </div>
                              </div>
                              <div className="col-lg-12 col-xl-6 col-12">
                                  <div className="split-inner">
                                      <h4 className="title">Continue Your Business With Us.</h4>
                                      <p className="description">At Techpoints , we understand how important the first look of a Company Website is! Hence, we work constantly to deliver the best look as per the requirement of our client. First impressions are very important and we aim to get that deal sealed for you right away.</p>
                                      <div className="row">
                                          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                              <div className="count-box counter-style-4 text-start">
                                                  <div>
                                                      <div className="count-number"><span className="counter">199</span></div>
                                                  </div>
                                                  <h5 className="counter-title">Happy Clients.</h5>
                                              </div>
                                          </div>
                                          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                              <div className="count-box counter-style-4 text-start">
                                                  <div>
                                                      <div className="count-number"><span className="counter">50</span></div>
                                                  </div>
                                                  <h5 className="counter-title">Employees</h5>
                                              </div>
                                          </div>
                                          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                              <div className="count-box counter-style-4 text-start">
                                                  <div>
                                                      <div className="count-number"><span className="counter">69</span></div>
                                                  </div>
                                                  <h5 className="counter-title">Useful Programs</h5>
                                              </div>
                                          </div>
                                          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                              <div className="count-box counter-style-4 text-start">
                                                  <div>
                                                      <div className="count-number"><span className="counter">500</span></div>
                                                  </div>
                                                  <h5 className="counter-title">Useful Programs</h5>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div><div className="rwt-testimonial-area rn-section-gap">
              <div className="container">
                  <div className="row">
                      <div className="col-lg-12">
                          <div className="section-title text-center mb--10" >
                              <h4 className="subtitle "><span className="theme-gradient">Client Feedback</span></h4>
                              <h2 className="title w-600">What People Say About Us.</h2>
                              <p className="description b1">We provide company and finance service for <br/> startups and
                                  company business.</p>
                          </div>
                      </div>
                  </div>
                  <div className="row row--15 mt_dec--30">
                      <div className="col-lg-4 col-md-6 col-12 mt--30" >
                          <div className="rn-box-card card-style-default testimonial-style-one style-two border-gradient">
                              <div className="inner">
                                  <div className="thumbnail"><img src="assets/images/testimonial/testimonials.jpg" alt="Corporate React Template"/></div>
                                  <div className="content">
                                      <p className="description">“The Better Software company is under new (old) management. Having been reborn and thrown off the shackles of U.S. Venture Capitalists,„</p>
                                      <h2 className="title">Sr Janen Sara</h2>
                                      <h6 className="subtitle theme-gradient">Sr Product Designer</h6>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12 mt--30" >
                          <div className="rn-box-card card-style-default testimonial-style-one style-two border-gradient">
                              <div className="inner">
                                  <div className="thumbnail"><img src="assets/images/testimonial/testimonials.jpg" alt="Corporate React Template"/></div>
                                  <div className="content">
                                      <p className="description">“This company is on a mission as lives and breathes franchising. Very focused. It is a flat hierarchy so everyone is given meaningful responsibility. Full benefits. „</p>
                                      <h2 className="title">Afsana Nila</h2>
                                      <h6 className="subtitle theme-gradient">App Developer</h6>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12 mt--30" >
                          <div className="rn-box-card card-style-default testimonial-style-one style-two border-gradient">
                              <div className="inner">
                                  <div className="thumbnail"><img src="assets/images/testimonial/testimonials.jpg" alt="Corporate React Template"/></div>
                                  <div className="content">
                                      <p className="description">“I never miss my deadline in the past 3 years.
                                          And I was respected and got awards from VPs and was also paid well too then.„<br /><br /></p>
                                      <h2 className="title">Afanan Sifa</h2>
                                      <h6 className="subtitle theme-gradient">Accounts Manager</h6>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div><div className="rbt-separator-mid">
              <div className="container">
                  <hr className="rbt-separator m-0"/>
                  </div>
          </div><div className="blog-area rn-section-gap" id="news">
              <div className="container">
                  <div className="row">
                      <div className="col-lg-12">
                          <div className="section-title text-center" >
                              <h4 className="subtitle "><span className="theme-gradient">Latests News</span></h4>
                              <h2 className="title w-600 mb--20">Our Latest News.</h2>
                              <p className="description b1">We provide company and finance service for <br/> startups and
                                  company business.</p>
                          </div>
                      </div>
                  </div>
                  <div className="row row--15">

                      <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt--30">
                          <div className="rn-card box-card-style-default">
                              <div className="inner">
                                  <div className="thumbnail"><a className="image" href="/"><img src="assets/images/blog/blog-01.jpg" alt="Blog Image"/></a></div>
                                  <div className="content">

                                      <h4 className="title"><a href="/">Best Corporate Tips You Will Read This
                                          Year.</a></h4>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt--30">
                          <div className="rn-card box-card-style-default">
                              <div className="inner">
                                  <div className="thumbnail"><a className="image" href="/"><img src="assets/images/blog/blog-02.jpg" alt="Blog Image"/></a></div>
                                  <div className="content">

                                      <h4 className="title"><a href="/">Should Fixing Corporate Take 100
                                          Steps.</a></h4>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt--30">
                          <div className="rn-card box-card-style-default">
                              <div className="inner">
                                  <div className="thumbnail">
                                      <a className="image" href="/"><img src="assets/images/blog/blog-03.jpg" alt="Blog Image"/></a>
                                  </div>
                                  <div className="content">

                                      <h4 className="title"><a href="/">The Next 100 Things To Immed Iately Do
                                          About.</a></h4>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        <Footer></Footer>
          <div className="copyright-area copyright-style-one">
              <div className="container">
                  <div className="row align-items-center">
                      <div className="col-lg-6 col-md-8 col-sm-12 col-12">
                          <div className="copyright-left">
                              <ul className="ft-menu link-hover">

                                  <li>
                                      <a href="/Contact">Contact Us</a>
                                  </li>
                              </ul>
                          </div>
                      </div>
                      <div className="col-lg-6 col-md-4 col-sm-12 col-12">
                          <div className="copyright-right text-center text-lg-end">
                              <p className="copyright-text">© Techpoints 2018</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
     
    

   </main>
    <div className="rn-back-top">
        <i className="feather-arrow-up"></i>
    </div>
    
    </div>
    
  )
}
