import React from 'react'
import { NavLink } from 'react-router-dom';
import Footer from './Footer';
export default function Team() {
    const openmenu = () => {
        var element = document.getElementById("phonemenu");
        element.classList.add("active");
    }
    const closemenu = () =>{
        var element = document.getElementById("phonemenu");
        element.classList.remove("active");
    }

  return (
    <div>
 <main className="page-wrapper">
       
        <div className="header-top-news bg-image1">
            
            <div className="icon-close">
               
            </div>
        </div>
        <header className="rn-header header-default header-left-align header-not-transparent header-sticky">
            <div className="container position-relative">
                <div className="row align-items-center">
                    <div className="col-lg-9 col-md-6 col-4 position-static">
                        <div className="header-left d-flex">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-light" src="assets/images/logo/logo.png" alt="Corporate Logo"/>
                                    <img className="logo-dark" src="assets/images/logo/logo.png" alt="Corporate Logo"/>
                                </a>
                            </div>
                            <nav className="mainmenu-nav d-none d-lg-block">
                                              <ul className="mainmenu">
                                                  <li className="has-droupdown has-menu-child-item"><NavLink to="/">Home</NavLink>
                                                  </li>
                                                  <li><NavLink to="/About">About</NavLink></li>
                                                  <li><NavLink to="/Service">Service</NavLink></li>
                                                  <li><NavLink to="/Team">Team</NavLink></li>
                                                  <li><NavLink to="/Gallery">Gallery</NavLink></li>
                                                  <li><NavLink to="/Pricing">Pricing</NavLink></li>
                                                  <li><NavLink to="/Careers">Careers</NavLink></li>
                                                  <li><NavLink to="/Contact">Contact</NavLink></li>
                                              </ul>
                                          </nav>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-8">
                        <div className="header-right">
                            <div className="header-btn">
                              
                                <ul className="social-icon social-default justify-content-start ">
                                        <li><a target="_blank" href="https://m.facebook.com/Techpoints/">
                                                <i className="feather-facebook"></i>
                                            </a>
                                        </li>
                                        <li className="d-none d-lg-block"><a target="_blank" href="https://twitter.com/Techpoints">
                                                <i className="feather-twitter"></i>
                                            </a>
                                        </li>
                                        <li className="d-none d-lg-block"><a target="_blank" href="https://www.instagram.com/Techpoints2018/?igshid=YmMyMTA2M2Y=">
                                                <i className="feather-instagram"></i>
                                            </a>
                                        </li>
                                        <li><a target="_blank" href="https://www.linkedin.com/in/Techpoints-pvt-ltd-353aab23a/">
                                                <i className="feather-linkedin"></i>
                                            </a>
                                        </li>
                                    </ul>
                            </div>

                          
                            <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                                <div className="hamberger">
                                    <button onClick={openmenu} className="hamberger-button">
                                        <i className="feather-menu"></i>
                                    </button>
                                </div>
                            </div>
                           

                           
                         

                        </div>
                    </div>
                </div>
            </div>
        </header>
       
        <div className='popup-mobile-menu ' id='phonemenu'>
            <div className="inner">
                <div className="header-top">
                    <div className="logo">
                        <a href="/">
                            <img className="logo-light" src="assets/images/logo/logo.png" alt="Corporate Logo"/>
                            <img className="logo-dark" src="assets/images/logo/logo.png" alt="Corporate Logo"/>
                        </a>
                    </div>
                    <div className="close-menu">
                        <button onClick={closemenu} className="close-button">
                            <i className="feather-x"></i>
                        </button>
                    </div>
                </div>
                <ul className="mainmenu">
                    
                        <li><NavLink to="/">Home</NavLink></li>
                        <li><NavLink to="/About">About</NavLink></li>
                        <li><NavLink to="/Service">Service</NavLink></li>
                        <li><NavLink to="/Team">Team</NavLink></li>
                        <li><NavLink to="/Gallery">Gallery</NavLink></li>
                        <li><NavLink to="/Pricing">Pricing</NavLink></li>
                        <li><NavLink to="/Careers">Careers</NavLink></li>
                        <li><NavLink to="/Contact">Contact</NavLink></li>
                </ul>
            </div>
        </div>


                  <div>
                      <div className="rn-gradient-circle"></div>
                      <div className="rn-gradient-circle theme-pink"></div>
                  </div>




                  <div className="main-content">


                      <div className="rwt-team-area rn-section-gap">
                          <div className="container">
                              <div className="row">
                                  <div className="col-lg-12">
                                      <div className="section-title text-center">
                                          <h4 className="subtitle "><span className="theme-gradient">Our Experts.</span></h4>
                                          <h2 className="title w-600 mb--20">Our Team </h2>
                                      </div>
                                  </div>
                              </div>
                              <div className="row row--15">
                                  <div className="col-lg-4 col-md-6 col-12 mt--30" >
                                      <div className="rn-team team-style-default">
                                          <div className="inner">
                                              <div className="thumbnail">
                                                  <img src="assets/images/team/team-01.jpg" alt="Corporate Template"/>
                                                  </div>
                                              <div className="content">
                                                  <h2 className="title">Sr Janen Sara</h2>
                                                  <h6 className="subtitle theme-gradient">Project Manager</h6>
                                                  <span className="team-form">
                                                      <i className="feather-map-pin"></i>
                                                      <span className="location">India</span>
                                                  </span>
                                                  <p className="description">Yes, I am a Project Manager. I have a passion for
                                                      Manager.</p>
                                                  <ul className="social-icon social-default icon-naked mt--20">
                                                      <li><a target="_blank" href="https://m.facebook.com/Techpoints/">
                                                          <i className="feather-facebook"></i>
                                                      </a>
                                                      </li>
                                                      <li><a target="_blank" href="https://twitter.com/Techpoints">
                                                          <i className="feather-twitter"></i>
                                                      </a>
                                                      </li>
                                                      <li><a target="_blank" href="https://www.instagram.com/accounts/login/?next=/Techpoints2018/">
                                                          <i className="feather-instagram"></i>
                                                      </a>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>

                                      </div>
                                  </div>

                                  <div className="col-lg-4 col-md-6 col-12 mt--30">
                                      <div className="rn-team team-style-default">
                                          <div className="inner">
                                              <div className="thumbnail">
                                                  <img src="assets/images/team/team-02.jpg" alt="Corporate Template"/>
                                                  </div>
                                              <div className="content">
                                                  <h2 className="title">Amitabh kumar</h2>
                                                  <h6 className="subtitle theme-gradient">Software Developer</h6>
                                                  <span className="team-form">
                                                      <i className="feather-map-pin"></i>
                                                      <span className="location">India</span>
                                                  </span>
                                                  <p className="description">Yes, I am a Software Developer. I have a passion for
                                                      Developer.</p>
                                                  <ul className="social-icon social-default icon-naked mt--20">
                                                      <li><a target="_blank" href="https://m.facebook.com/Techpoints/">
                                                          <i className="feather-facebook"></i>
                                                      </a>
                                                      </li>
                                                      <li><a target="_blank" href="https://twitter.com/Techpoints">
                                                          <i className="feather-twitter"></i>
                                                      </a>
                                                      </li>
                                                      <li><a target="_blank" href="https://www.instagram.com/accounts/login/?next=/Techpoints2018/">
                                                          <i className="feather-instagram"></i>
                                                      </a>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                  </div>

                                  <div className="col-lg-4 col-md-6 col-12 mt--30">
                                      <div className="rn-team team-style-default">
                                          <div className="inner">
                                              <div className="thumbnail">
                                                  <img src="assets/images/team/team-03.jpg" alt="Corporate Template"/>
                                                  </div>
                                              <div className="content">
                                                  <h2 className="title">Sr Janen Sara</h2>
                                                  <h6 className="subtitle theme-gradient">Sr Product Designer</h6>
                                                  <span className="team-form">
                                                      <i className="feather-map-pin"></i>
                                                      <span className="location">India</span>
                                                  </span>
                                                  <p className="description">Yes, I am a product designer. I have a passion for
                                                      Sr Product Designer.</p>
                                                  <ul className="social-icon social-default icon-naked mt--20">
                                                      <li><a target="_blank" href="https://m.facebook.com/Techpoints/">
                                                          <i className="feather-facebook"></i>
                                                      </a>
                                                      </li>
                                                      <li><a target="_blank" href="https://twitter.com/Techpoints">
                                                          <i className="feather-twitter"></i>
                                                      </a>
                                                      </li>
                                                      <li><a target="_blank" href="https://www.instagram.com/accounts/login/?next=/Techpoints2018/">
                                                          <i className="feather-instagram"></i>
                                                      </a>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>




                  </div>

              </main>

        <Footer></Footer>
          <div className="copyright-area copyright-style-one">
              <div className="container">
                  <div className="row align-items-center">
                      <div className="col-lg-6 col-md-8 col-sm-12 col-12">
                          <div className="copyright-left">
                              <ul className="ft-menu link-hover">

                                  <li>
                                      <a href="/Contact">Contact Us</a>
                                  </li>
                              </ul>
                          </div>
                      </div>
                      <div className="col-lg-6 col-md-4 col-sm-12 col-12">
                          <div className="copyright-right text-center text-lg-end">
                              <p className="copyright-text">© Techpoints 2018</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

      {/* </main> */}
      <div className="rn-back-top">
              <i className="feather-arrow-up"></i>
          </div>
    </div>
  )
}
