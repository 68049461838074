import React from 'react'

export default function Footer() {
  return (
    <div>

<footer className="rn-footer footer-style-default variation-two">
            <div className="rn-callto-action clltoaction-style-default style-7">
                <div className="container">
                    
                </div>
            </div>
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                            <div className="rn-footer-widget">
                                <h4 className="title">Services</h4>
                                <div className="inner">
                                    <ul className="footer-link link-hover">
                                        <li><a href="/Service">Web Analytics</a></li>
                                        <li><a href="/Service">Technical SEO Audit</a></li>
                                        <li><a href="/Service">Online Marketing</a></li>
                                        <li><a href="/Service">Site Mapping</a></li>
                                         <li><a href="/Service">Payment Gateway</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                            <div className="rn-footer-widget">
                                <div className="widget-menu-top">
                                    <h4 className="title">Solutions</h4>
                                    <div className="inner">
                                        <ul className="footer-link link-hover">
                                            <li><a href="/Service">Media Promotion</a></li>
                                            <li><a href="/Service">Managment & Marketing</a></li>
                                           
                                            <li><a href="/Service">Responsive Web Design</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                            <div className="rn-footer-widget">
                                <h4 className="title">Company</h4>
                                <div className="inner">
                                    <ul className="footer-link link-hover">
                                        <li><a href="/About">About</a></li>
                                        <li><a href="/Gallery">Gallery</a></li>
                                        <li><a href="/Contact">Contact</a></li>
                                        <li><a href="/Service">Service</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                            <div className="rn-footer-widget">
                                <h4 className="title">Resources</h4>
                                <div className="inner">
                                    <ul className="footer-link link-hover">
                                        <li><a href="/Team">Team</a></li>
                                        <li><a href="/Pricing">Pricing</a></li>
                                        <li><a href="/Service">Service</a></li>
                                        <li><a href="/Careers">Careers</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="rn-footer-widget">
                                <h4 className="title">Stay With Us.</h4>
                                <div className="inner">
                                    <h6 className="subtitle">2000+ Our clients are subscribe Around the World</h6>
                                    <ul className="social-icon social-default justify-content-start ">
                                        <li><a target="_blank" href="https://m.facebook.com/Techpoints/">
                                                <i className="feather-facebook"></i>
                                            </a>
                                        </li>
                                        <li className=""><a target="_blank" href="https://twitter.com/Techpoints">
                                                <i className="feather-twitter"></i>
                                            </a>
                                        </li>
                                        <li className=""><a target="_blank" href="https://www.instagram.com/Techpoints2018/?igshid=YmMyMTA2M2Y=">
                                                <i className="feather-instagram"></i>
                                            </a>
                                        </li>
                                        <li><a target="_blank" href="https://www.linkedin.com/in/Techpoints-pvt-ltd-353aab23a/">
                                                <i className="feather-linkedin"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
       

    </div>
  )
}
