import React from 'react'

export default function Navbar() {
  return (
    <div>
         <header className="rn-header header-default header-left-align header-not-transparent header-sticky">
            <div className="container position-relative">
                <div className="row align-items-center">
                    <div className="col-lg-9 col-md-6 col-4 position-static">
                        <div className="header-left d-flex">
                            <div className="logo">
                                <a href="index-2.html">
                                    <img className="logo-light" src="assets/images/logo/logo.png" alt="Corporate Logo"/>
                                    <img className="logo-dark" src="assets/images/logo/logo.png" alt="Corporate Logo"/>
                                </a>
                            </div>
                            <nav className="mainmenu-nav d-none d-lg-block">
                                <ul className="mainmenu">
                                    <li className="has-droupdown has-menu-child-item"><a href="index-2.html">Home</a>
                                    </li>
                                    <li><a href="about.html">About</a></li>
                                    <li><a href="service.html">Service</a></li>
                                    <li><a href="team.html">Team</a></li>
                                    <li><a href="gallery.html">Gallery</a></li>
                                    <li><a href="pricing.html">Pricing</a></li>
                                 
                                    <li><a href="careers.html">Careers</a></li>
                                    <li><a href="contact.html">Contact</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-8">
                        <div className="header-right">
                            <div className="header-btn">
                               
                                <ul className="social-icon social-default justify-content-start ">
                                        <li><a target="_blank" href="https://m.facebook.com/Techpoints/">
                                                <i className="feather-facebook"></i>
                                            </a>
                                        </li>
                                        <li className="d-none d-lg-block"><a target="_blank" href="https://twitter.com/Techpoints">
                                                <i className="feather-twitter"></i>
                                            </a>
                                        </li>
                                        <li className="d-none d-lg-block"><a target="_blank" href="https://www.instagram.com/Techpoints2018/?igshid=YmMyMTA2M2Y=">
                                                <i className="feather-instagram"></i>
                                            </a>
                                        </li>
                                        <li><a target="_blank" href="https://www.linkedin.com/in/Techpoints-pvt-ltd-353aab23a/">
                                                <i className="feather-linkedin"></i>
                                            </a>
                                        </li>
                                    </ul>
                            </div>

                          
                            <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                                <div className="hamberger">
                                    <button  className="hamberger-button">
                                        <i className="feather-menu"></i>
                                    </button>
                                </div>
                            </div>
                          

                          
                            <div id="my_switcher" className="my_switcher">
                                <ul>
                                    <li>
                                        <a href="" className="setColor light">
                                            <img className="sun-image" src="assets/images/icons/sun-01.svg" alt="Sun images"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href=""  className="setColor dark">
                                            <img className="Victor Image" src="assets/images/icons/vector.svg" alt="Vector Images"/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                           

                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div className="popup-mobile-menu ">
            <div className="inner">
                <div className="header-top">
                    <div className="logo">
                        <a href="index-2.html">
                            <img className="logo-light" src="assets/images/logo/logo.png" alt="Corporate Logo"/>
                            <img className="logo-dark" src="assets/images/logo/logo-dark.png" alt="Corporate Logo"/>
                        </a>
                    </div>
                    <div className="close-menu">
                        <button className="close-button">
                            <i className="feather-x"></i>
                        </button>
                    </div>
                </div>
                <ul className="mainmenu">
                    <li className="has-droupdown has-menu-child-item"><a href="index-2.html">Home</a> </li>
                    <li><a href="about.html">About</a></li>
                    
                    <li><a href="about.html">About</a></li>
                    <li><a href="service.html">Service</a></li>
                    <li><a href="team.html">Team</a></li>
                    <li><a href="gallery.html">Gallery</a></li>
                    <li><a href="pricing.html">Pricing</a></li>
                  
                    <li><a href="careers.html">Careers</a></li>
                    <li><a href="contact.html">Contact</a></li>
                </ul>

            </div>
        </div>

        
        <div>
            <div className="rn-gradient-circle"></div>
            <div className="rn-gradient-circle theme-pink"></div>
        </div>
    </div>
  )
}
