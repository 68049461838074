import React from 'react';
import Home from './Home';
import Careers from './Careers';
import Contact from './Contact';
import Gallery from './Gallery';
import Pricing from './Pricing';
import Service from './Service';
import Team from './Team';
import { NavLink } from 'react-router-dom';
import Footer from './Footer';

export default function About() {
    const openmenu = () => {
        var element = document.getElementById("phonemenu");
        element.classList.add("active");
    }
    const closemenu = () =>{
        var element = document.getElementById("phonemenu");
        element.classList.remove("active");
    }

  return (
    <div>
 <main className="page-wrapper">
       
        <div className="header-top-news bg-image1">
            
            <div className="icon-close">
               
            </div>
        </div>
        <header className="rn-header header-default header-left-align header-not-transparent header-sticky">
            <div className="container position-relative">
                <div className="row align-items-center">
                    <div className="col-lg-9 col-md-6 col-4 position-static">
                        <div className="header-left d-flex">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-light" src="assets/images/logo/logo.png" alt="Corporate Logo"/>
                                    <img className="logo-dark" src="assets/images/logo/logo.png" alt="Corporate Logo"/>
                                </a>
                            </div>
                            <nav className="mainmenu-nav d-none d-lg-block">
                                              <ul className="mainmenu">
                                                  <li className="has-droupdown has-menu-child-item"><NavLink to="/">Home</NavLink>
                                                  </li>
                                                  <li><NavLink to="/About">About</NavLink></li>
                                                  <li><NavLink to="/Service">Service</NavLink></li>
                                                  <li><NavLink to="/Team">Team</NavLink></li>
                                                  <li><NavLink to="/Gallery">Gallery</NavLink></li>
                                                  <li><NavLink to="/Pricing">Pricing</NavLink></li>
                                                  <li><NavLink to="/Careers">Careers</NavLink></li>
                                                  <li><NavLink to="/Contact">Contact</NavLink></li>
                                              </ul>
                                          </nav>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-8">
                        <div className="header-right">
                            <div className="header-btn">
                              
                                <ul className="social-icon social-default justify-content-start ">
                                        <li><a target="_blank" href="https://m.facebook.com/Techpoints/">
                                                <i className="feather-facebook"></i>
                                            </a>
                                        </li>
                                        <li className="d-none d-lg-block"><a target="_blank" href="https://twitter.com/Techpoints">
                                                <i className="feather-twitter"></i>
                                            </a>
                                        </li>
                                        <li className="d-none d-lg-block"><a target="_blank" href="https://www.instagram.com/Techpoints2018/?igshid=YmMyMTA2M2Y=">
                                                <i className="feather-instagram"></i>
                                            </a>
                                        </li>
                                        <li><a target="_blank" href="https://www.linkedin.com/in/Techpoints-pvt-ltd-353aab23a/">
                                                <i className="feather-linkedin"></i>
                                            </a>
                                        </li>
                                    </ul>
                            </div>

                          
                            <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                                <div className="hamberger">
                                    <button onClick={openmenu} className="hamberger-button">
                                        <i className="feather-menu"></i>
                                    </button>
                                </div>
                            </div>
                           

                           
                        
                          

                        </div>
                    </div>
                </div>
            </div>
        </header>
       
        <div className='popup-mobile-menu ' id='phonemenu'>
            <div className="inner">
                <div className="header-top">
                    <div className="logo">
                        <a href="/">
                            <img className="logo-light" src="assets/images/logo/logo.png" alt="Corporate Logo"/>
                            <img className="logo-dark" src="assets/images/logo/logo.png" alt="Corporate Logo"/>
                        </a>
                    </div>
                    <div className="close-menu">
                        <button onClick={closemenu} className="close-button">
                            <i className="feather-x"></i>
                        </button>
                    </div>
                </div>
                <ul className="mainmenu">
                    
                        <li><NavLink to="/">Home</NavLink></li>
                        <li><NavLink to="/About">About</NavLink></li>
                        <li><NavLink to="/Service">Service</NavLink></li>
                        <li><NavLink to="/Team">Team</NavLink></li>
                        <li><NavLink to="/Gallery">Gallery</NavLink></li>
                        <li><NavLink to="/Pricing">Pricing</NavLink></li>
                        <li><NavLink to="/Careers">Careers</NavLink></li>
                        <li><NavLink to="/Contact">Contact</NavLink></li>
                </ul>
            </div>
        </div>

        <div>
            <div className="rn-gradient-circle"></div>
            <div className="rn-gradient-circle theme-pink"></div>
        </div>
    


        
        <div className="slider-area slider-style-1 variation-default height-850 bg_image bg_image--12" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner pt--80 text-center" >
                            <div>
                                <h3 className="rn-sub-badge"><span className="theme-gradient">Our Company's About
                                        Details.</span></h3>
                            </div>
                            <h1 className="title display-one">Largest Advertising <br/> Agency in India</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="service-area rn-section-gapTop">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="content">
                            <h3 className="title">We are creative digital agency working for our company brands.</h3>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <p className="mb--10">At Techpoints , we understand how important the first look of a Company Website is! Hence, we work constantly to deliver the best look as per the requirement of our client. First impressions are very important and we aim to get that deal sealed for you right away. Hence, our team of designers and developers not only create, but also work as consultants and advisors to bring out the best that your firm has to offer.</p>
                    </div>
                </div>
            </div>
        </div>
       

       
       
        <div className="rbt-separator-mid">
            <div className="container">
                <hr className="rbt-separator m-0"/>
            </div>
        </div>
       
        <div className="rn-service-area rn-section-gap">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title text-center" >
                            <h4 className="subtitle">
                                <span className="theme-gradient">What we can do for you</span>
                            </h4>
                            <h2 className="title w-600 mb--20">Services provide for you.</h2>
                            <p className="description b1">There are many variations of passages of Lorem Ipsum
                                available, <br/>but the majority have suffered alteration.</p>
                        </div>
                    </div>
                </div>

                <div className="row row--15 service-wrapper">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" >
                        <div className="service service__style--1 bg-color-blackest radius mt--20 rbt-border text-start">
                            <div className="icon">
                                <i className="feather-activity"></i>
                            </div>
                            <div className="content">
                                <h4 className="title w-600">
                                    <a href="#">Web Analytics</a>
                                </h4>
                                <p className="description b1 color-gray mb--0">Techpoints specializing in customize software development, Web Analytics services .</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" >
                        <div className="service service__style--1 bg-color-blackest radius mt--20 rbt-border text-start">
                            <div className="icon">
                                <i className="feather-cast"></i>
                            </div>
                            <div className="content">
                                <h4 className="title w-600">
                                    <a href="#">Web Design & Development </a>
                                </h4>
                                <p className="description b1 color-gray mb--0">Techpoints is a house of Innovative & Creative custom Web design and Web Development Company.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" >
                        <div className="service service__style--1 bg-color-blackest radius mt--20 rbt-border text-start">
                            <div className="icon">
                                <i className="feather-map"></i>
                            </div>
                            <div className="content">
                                <h4 className="title w-600">
                                    <a href="#">Android  App Development</a>
                                </h4>
                                <p className="description b1 color-gray mb--0">Techpoints is a house of Innovative & Creative custom Android design and Development Company.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
        <div className="rwt-timeline-area rn-section-gapBottom">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="timeline-style-two bg-color-blackest">
                            <div className="row row--0">
                                <div className="col-lg-3 col-md-3 rn-timeline-single no-gradient">
                                    <div className="rn-timeline">
                                        <h6 className="title" data-sal="slide-up" >Knowledge</h6>
                                        <div className="progress-line">
                                            <div className="line-inner"></div>
                                        </div>
                                        <div className="progress-dot">
                                            <div className="dot-level">
                                                <div className="dot-inner"></div>
                                            </div>
                                        </div>
                                        <p className="description" data-sal="slide-up" >Present all available features in Essentials.</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 rn-timeline-single no-gradient">
                                    <div className="rn-timeline">
                                        <h6 className="title" data-sal="slide-up" >Working</h6>
                                        <div className="progress-line">
                                            <div className="line-inner"></div>
                                        </div>
                                        <div className="progress-dot">
                                            <div className="dot-level">
                                                <div className="dot-inner"></div>
                                            </div>
                                        </div>
                                        <p className="description" data-sal="slide-up" >All Feature available features in Essentials.</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 rn-timeline-single no-gradient">
                                    <div className="rn-timeline">
                                        <h6 className="title" data-sal="slide-up" >Solution</h6>
                                        <div className="progress-line">
                                            <div className="line-inner"></div>
                                        </div>
                                        <div className="progress-dot">
                                            <div className="dot-level">
                                                <div className="dot-inner"></div>
                                            </div>
                                        </div>
                                        <p className="description" data-sal="slide-up" >Popular Feature available features in Essentials.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 rn-timeline-single no-gradient">
                                    <div className="rn-timeline">
                                        <h6 className="title" data-sal="slide-up">Process</h6>
                                        <div className="progress-line">
                                            <div className="line-inner"></div>
                                        </div>
                                        <div className="progress-dot">
                                            <div className="dot-level">
                                                <div className="dot-inner"></div>
                                            </div>
                                        </div>
                                        <p className="description" data-sal="slide-up" >Latest Feature available features in Essentials.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
        <div className="rbt-separator-mid">
            <div className="container">
                <hr className="rbt-separator m-0"/>
            </div>
        </div>
        
        <div className="about-area about-style-4 rn-section-gap">
            <div className="container">
                <div className="row row--40 align-items-center">
                    <div className="col-lg-6">
                        <div className="video-btn">
                            <div className="video-popup icon-center">
                                <div className="overlay-content">
                                    <div className="thumbnail"><img className="radius-small" src="assets/images/about/contact-image.jpg" alt="Corporate Image"/></div>
                                    <div className="video-icon">
                                        <a className="btn-default rounded-player popup-video" href="https://www.youtube.com/watch?v=tj9-MGHCs38">
                                            <span><i className="feather-play"></i></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="video-lightbox-wrapper"></div>
                        </div>
                    </div>
                    <div className="col-lg-6 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="inner">
                                <h3 className="title">Techpoints specializes in <br/> small <strong>Corporate Business.</strong>
                                </h3>
                                <ul className="feature-list">
                                    <li>
                                        <div className="icon">
                                            <i className="feather-check"></i>
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">A good traveler has no fixed plans</h4>
                                            <p className="text">Lorem ipsum dolor consectetur adipiscing do eiusmod tempor
                                                incididunt labore.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <i className="feather-check"></i>
                                        </div>
                                        <div className="title-wrapper">
                                            <h4 className="title">A good traveler has no fixed plans</h4>
                                            <p className="text">Lorem ipsum dolor consectetur adipiscing do eiusmod tempor
                                                incididunt labore.</p>
                                        </div>
                                    </li>
                                </ul>
                                <div className="about-btn mt--30"><a className="btn-default" href="#">About Our Techpoints</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="rwt-counterup-area pb--100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 sal-animate" >
                        <div className="count-box counter-style-4 text-center">
                            <div>
                                <div className="count-number"><span className="counter">199</span></div>
                            </div>
                            <h5 className="counter-title">Happy Clients.</h5>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 sal-animate" >
                        <div className="count-box counter-style-4 text-center">
                            <div>
                                <div className="count-number"><span className="counter">575</span></div>
                            </div>
                            <h5 className="counter-title">Employees</h5>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 sal-animate" >
                        <div className="count-box counter-style-4 text-center">
                            <div>
                                <div className="count-number"><span className="counter">69</span></div>
                            </div>
                            <h5 className="counter-title">Useful Programs</h5>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 sal-animate" >
                        <div className="count-box counter-style-4 text-center">
                            <div>
                                <div className="count-number"><span className="counter">500</span></div>
                            </div>
                            <h5 className="counter-title">Useful Programs</h5>
                        </div>
                    </div>

                </div>
            </div>
        </div>
       
        <div className="rbt-separator-mid">
            <div className="container">
                <hr className="rbt-separator m-0"/>
            </div>
        </div>
        
        <div className="rwt-team-area rn-section-gap">
            <div className="container">
                <div className="row mb--20">
                    <div className="col-lg-12">
                        <div className="section-title text-center" >
                            <h4 className="subtitle "><span className="theme-gradient">Our Techpoints Team.</span></h4>
                            <h2 className="title w-600 mb--20">Techpoints Team Member.</h2>
                        </div>
                    </div>
                </div>
                <div className="row row--15">
                    <div className="col-lg-6 col-xl-3 col-md-6 col-12 mt--30" >
                        <div className="rn-team team-style-three">
                            <div className="inner">
                                <div className="thumbnail"><img src="assets/images/team/team-02.jpg" alt="Corporate React Template"/></div>
                                <div className="content">
                                    <div className="team-info">
                                        <h2 className="title">Amitabh kumar</h2>
                                        <h6 className="subtitle theme-gradient">Software Developer</h6>
                                        <div className="team-form"><span className="location">India</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 col-md-6 col-12 mt--30" >
                        <div className="rn-team team-style-three">
                            <div className="inner">
                                <div className="thumbnail"><img src="assets/images/team/team-dark-02.jpg" alt="Corporate React Template"/></div>
                                <div className="content">
                                    <div className="team-info">
                                        <h2 className="title">Corporate Jane</h2>
                                        <h6 className="subtitle theme-gradient">Manager</h6>
                                        <div className="team-form"><span className="location">Bangladesh</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 col-md-6 col-12 mt--30" >
                        <div className="rn-team team-style-three">
                            <div className="inner">
                                <div className="thumbnail"><img src="assets/images/team/team-dark-03.jpg" alt="Corporate React Template"/></div>
                                <div className="content">
                                    <div className="team-info">
                                        <h2 className="title">Jara Saraif</h2>
                                        <h6 className="subtitle theme-gradient">Software Developer</h6>
                                        <div className="team-form"><span className="location">Poland</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 col-md-6 col-12 mt--30" >
                        <div className="rn-team team-style-three">
                            <div className="inner">
                                <div className="thumbnail"><img src="assets/images/team/team-dark-04.jpg" alt="Corporate React Template"/></div>
                                <div className="content">
                                    <div className="team-info">
                                        <h2 className="title">Afanan Sifa</h2>
                                        <h6 className="subtitle theme-gradient">Accounts Manager</h6>
                                        <div className="team-form"><span className="location">Poland</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
        <div className="copyright-area copyright-style-one">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-8 col-sm-12 col-12">
                        <div className="copyright-left">
                            <ul className="ft-menu link-hover">
                                
                                <li>
                                    <a href="/Contact">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-4 col-sm-12 col-12">
                        <div className="copyright-right text-center text-lg-end">
                            <p className="copyright-text">© Techpoints 2018</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    </main>

    
    <div className="rn-back-top">
        <i className="feather-arrow-up"></i>
    </div>
    </div>
  )
}
